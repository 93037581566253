import React from "react";
import "./style.css";

const Loader = () => {
  return (
    <div class="spinner-border spinner-color " role="status">
      <span class="visually-hidden" />
    </div>
  );
};

export default Loader;
